// witness_historical.vue
// Witness historical chart

<template>
  <v-card tile class="pa-2 historical-card rounded-lg" flat>
    <v-card-title class="pt-0 pb-3">{{ cardTitle }}
      <div class="flex-grow-1"></div>
      <v-btn title="Data download" text small :loading="loading" icon :color="csv_dl_color_override || 'rgba(255, 166, 87, 0.6)'" @click="dl_csv">
        <v-icon class="icon-lg">$riwIconSet_csv</v-icon>
      </v-btn>
    </v-card-title>
    <v-overlay
      absolute
      opacity=0
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="rgba(255, 166, 87, 0.6)"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay
      absolute
      :value="loading"
    >
    </v-overlay>
    <v-overlay
      z-index="2"
      absolute
      :value="noData"
      opacity="0.2"
    >
      <p style="color: rgba(255, 255, 255, .3); font-size: min(100px, max(6vw, 56px)); font-weight: bold;">
        No Data
      </p>
    </v-overlay>
    <div class="smy_legend_padding">
      <v-card-text>
        <v-container fluid class="pa-0 ma-0">
          <v-row>
            <v-col cols=12 class="pa-0">
              <!-- <vue-plotly v-if="temperature_data" class="smy_plotly_chart" :data="temperature_data" :layout="temperature_layout" :options="temperature_options" :autoResize="autoResize" ></vue-plotly> -->
              <vue-plotly
              v-if="metadata && !loading"
              ref="hist_ref"
              @hover="hist_hover"
              class="plotlyDiv"
              :data="histData.data"
              :layout="histData.layout"
              :options="generic_chart_options"
              :autoResize="autoResize">
              </vue-plotly>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </div>
  </v-card>
</template>

<style scoped>
loading {
  opacity: 0.6
}
* >>> .hovertext {
  opacity: 0.8
}
.smy_subheading {
  padding: 6px 0px 6px 8px;
  /* margin-top: -16px; */
  margin-top: -32px !important;
  color: grey;
}
.historical-card {
  min-height: 431px;
}
</style>

<script>
import witnessHistoricalComponentModel from '@/component-models/witness-historical-component-model';

export default witnessHistoricalComponentModel;

</script>
