// Witness summary control

<template>
  <v-container fluid class="summaryWrapper">
    <v-row>
      <v-col cols="8" class="mb-2">
        <h2>{{ name }}</h2>
        <v-expand-transition>
          <p v-if="showDescription">
          {{ description }}
          </p>
        </v-expand-transition>
      </v-col>
      <v-col cols="4" class="mb-2 d-flex justify-end">
        <v-btn v-if="!loading && !noSummaryData && !hideAddButton && !added" x-small icon title="Add to dashboard" @click="addMetricToConfigs"><v-icon>$riwIconSet_addToDashboard</v-icon></v-btn>
        <span v-if="!loading && noSummaryData">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="gray"
                v-bind="attrs"
                v-on="on"
                class="mr-1"
                x-small
              >
                $riwIconSet_warning
              </v-icon>
              <span
                v-bind="attrs"
                v-on="on"
              >[ Error - No summary data available ]</span>
            </template>
            <span>This may be an intermittent problem, but if it persists there could be another cause: <br /> - If this item uses a saved interval, make sure the interval still exists. <br /> - An error may also occur if you no longer have access to the relevant clinic(s).</span>
          </v-tooltip><pre style="display: inline;"></pre></span>
        <v-icon color="#69F0AE" class="mr-1 ml-4" size=18 v-if="!loading && !noSummaryData && !hideAddButton && added">$riwIconSet_done</v-icon>
        <span v-if="loading"><!--<v-progress-circular indeterminate size=20 width=3 color="rgba(255, 166, 87, 0.6)" /> --><pre style="display: inline;"></pre>Loading...<pre style="display: inline;">    </pre></span>
        <v-btn v-if="showManagementControls" x-small icon title="Remove" class="ml-4 mr-1" @click="removeMetricFromConfigs"><v-icon>$riwIconSet_delete</v-icon></v-btn>
        <v-btn v-if="showManagementControls" x-small icon title="Rename" class="mr-1" @click="renameMetric"><v-icon>$riwIconSet_edit</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-card outlined width="100%" class="ma-0 pa-3 summaryPanel" min-height="90">
      <v-overlay
        absolute
        opacity=0
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="rgba(255, 166, 87, 0.6)"
        ></v-progress-circular>
      </v-overlay>
      <v-overlay
        absolute
        :value="loading"
      >
      </v-overlay>
      <v-overlay
        z-index="2"
        absolute
        :value="noData"
        opacity="0.2"
      >
        <div style="color: rgba(255, 255, 255, .3); font-size: min(100px, max(6vw, 56px)); font-weight: bold;">
          No Data
        </div>
      </v-overlay>
        <v-container fluid class="ma-0 pa-0">
          <v-row>
            <v-col cols=12 v-if="!loading && !noSummaryData">
              <v-row>
                <v-col cols=12 xs=12 sm=12 md=12 lg=4 xl=4 class="text-center pt-2">
                  <h3 v-if="dateFilter.dateRangeTitle" class="summary_date_range_title ">
                    {{ dateFilter.dateRangeTitle }}:
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="gray"
                          v-bind="attrs"
                          v-on="on"
                          x-small
                        >
                          $riwIconSet_info
                        </v-icon>
                      </template>
                      <span>{{currentDateRangeString}}</span>
                    </v-tooltip>
                  </h3>
                  <div class="summary_data_container mt-2">
                    <div class="left summary_data_sub_container">
                    </div>
                    <div class="center summary_data_sub_container">
                      <div class="dataBox">
                        <b v-if="mode === 'avgDuration'">
                          {{ summaryData.current.averageDuration }}
                        </b>
                        <b v-else-if="mode === 'total'">
                          {{ summaryData.current.totalDuration }}
                        </b>
                        <b v-else-if="mode === 'count'">
                          {{ summaryData.current.count }}
                        </b>
                      </div>
                    </div>
                    <div class="right summary_data_sub_container">
                      <span class="text-caption ml-2" v-if="mode !== 'count'" style="font-size: 15px !important;">
                        n={{ summaryData.current.countWithDuration }}
                      </span>
                    </div>
                  </div>
                  <v-divider v-if="$vuetify.breakpoint.mdAndDown" class="mt-2" />
                </v-col>
                <v-divider vertical v-if="dateFilter.dateRangeTitle && $vuetify.breakpoint.lgAndUp"/>
                <v-col cols=12 xs=12 sm=12 md=12 lg=4 xl=4 v-if="dateFilter.dateRangeTitle" class="text-center pt-2">
                  <h3 class="summary_date_range_title ">
                    vs. Previous {{dateFilter.jumpAmount == 1 ? dateFilter.jumpPeriod : dateFilter.jumpAmount + ' ' + dateFilter.jumpPeriod + 's'}}:
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="gray"
                          v-bind="attrs"
                          v-on="on"
                          x-small
                        >
                          $riwIconSet_info
                        </v-icon>
                      </template>
                      <span>{{previousDateRangeString}}</span>
                    </v-tooltip>
                  </h3>
                  <div class="summary_data_container mt-2">
                    <div class="left summary_data_sub_container">
                      <percentChangedWithArrow v-if="mode === 'avgDuration'" :percentChanged="summaryData.previous.deltaPercents.avgDuration"/>
                      <percentChangedWithArrow v-else-if="mode === 'total'" :percentChanged="summaryData.previous.deltaPercents.totalDuration"/>
                      <percentChangedWithArrow v-else-if="mode === 'count'" :percentChanged="summaryData.previous.deltaPercents.count"/>
                    </div>
                    <div class="center summary_data_sub_container">
                      <div class="dataBox">
                        <b v-if="mode === 'avgDuration'">
                          {{ summaryData.previous.averageDuration }}
                        </b>
                        <b v-else-if="mode === 'total'">
                          {{ summaryData.previous.totalDuration }}
                        </b>
                        <b v-else-if="mode === 'count'">
                          {{ summaryData.previous.count }}
                        </b>
                      </div>
                    </div>
                    <div class="right summary_data_sub_container">
                      <span v-if="mode !== 'count'" class="text-caption ml-2" style="font-size: 15px !important;">
                        n={{ summaryData.previous.countWithDuration }}
                      </span>
                    </div>
                  </div>
                  <v-divider v-if="$vuetify.breakpoint.mdAndDown" class="mt-2" />
                </v-col>
                <v-divider vertical v-if="dateFilter.dateRangeTitle && $vuetify.breakpoint.lgAndUp"/>
                <v-col cols=12 xs=12 sm=12 md=12 lg=4 xl=4 v-if="dateFilter.dateRangeTitle" class="text-center pt-2">
                  <h3 class="summary_date_range_title ">
                    vs. Previous {{ apiDateMultiplier * dateFilter.jumpAmount }} {{dateFilter.jumpPeriod}}s average:
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="gray"
                          v-bind="attrs"
                          v-on="on"
                          x-small
                        >
                          $riwIconSet_info
                        </v-icon>
                      </template>
                      <span>{{longTermComparisonDateRangeString}}</span>
                    </v-tooltip>
                  </h3>
                  <div class="summary_data_container mt-2">
                    <div class="left summary_data_sub_container">
                      <percentChangedWithArrow v-if="mode === 'avgDuration'" :percentChanged="summaryData.averagePrevious.deltaPercents.avgDuration"/>
                      <percentChangedWithArrow v-else-if="mode === 'total'" :percentChanged="summaryData.averagePrevious.deltaPercents.totalDuration"/>
                      <percentChangedWithArrow v-else-if="mode === 'count'" :percentChanged="summaryData.averagePrevious.deltaPercents.count"/>
                    </div>
                    <div class="center summary_data_sub_container">
                      <div class="dataBox">
                        <b v-if="mode === 'avgDuration'">
                          {{ summaryData.averagePrevious.averageDuration }}
                        </b>
                        <b v-else-if="mode === 'total'">
                          {{ summaryData.averagePrevious.totalDuration }}
                        </b>
                        <b v-else-if="mode === 'count'">
                          {{ summaryData.averagePrevious.count }}
                        </b>
                      </div>
                    </div>
                    <div class="right summary_data_sub_container">
                      <span v-if="mode !== 'count'" class="text-caption ml-2" style="font-size: 15px !important;">
                        n={{ summaryData.averagePrevious.countWithDuration }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<style scoped>
.justify-child-rows-center > .row {
  justify-content: center;
}
.dataBox {
    display:inline-block;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    padding: 5px 8px;
    /* margin: 10px 0 5px; */
    font-size: 32px;
}

.summary_data_container {
  display: flex;
}
.left.summary_data_sub_container, .right.summary_data_sub_container {
  flex: 1;
  display: flex;
  min-width: -webkit-min-content; /* Workaround to Chrome bug */
  align-items: flex-end;
}
.left.summary_data_sub_container {
  justify-content: flex-end;
}
.right.summary_data_sub_container {
  margin-right: auto;
}
</style>

<script>
import witnessSummaryComponentModel from '@/component-models/witness-summary-component-model';

export default witnessSummaryComponentModel;

</script>
