<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    :nudge-right="33"
    :nudge-top="20"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="datePickerValue"
        label="Date"
        prepend-icon="event"
        readonly
        v-on="on"
        persistent
        outlined
        dense
      >
        <template v-slot:append-outer>
          <div class="smy_btngroup" style="border-color: rgba(0, 0, 0, 0.54); margin-top: -5px">
            <v-btn fab x-small elevation=2 color="white" class="wiq--blue--text mr-1" :disabled ="disableButtons" @click="clickPreviousMonth" id="previous_month"><v-icon x-small>$riwIconSet_back</v-icon></v-btn>
            <v-btn fab x-small elevation=2 color="white" class="wiq--blue--text" :disabled ="disableButtons" @click="clickNextMonth" id="next_month"><v-icon x-small>$riwIconSet_forward</v-icon></v-btn>
            <v-btn fab x-small elevation=2 color="white" class="wiq--blue--text ml-4 mr-12" @click="resetDate" title="Latest data"><v-icon size=15>$riwIconSet_reload</v-icon></v-btn>
          </div>
        </template>
      </v-text-field>
    </template>
    <v-date-picker type="month" :value="datePickerValue" @input="updateDatePicker" :max="maximumDate"></v-date-picker>
  </v-menu>
</template>

<script>

import witnessDatePickerComponentModel from '@/component-models/witness-date-picker-component-model';

export default witnessDatePickerComponentModel;

</script>
