// witness_histogram.vue
// Witness histogram control

<template>
  <v-card tile class="pa-2 smycard histogram-card rounded-lg" flat>
    <v-card-title class="pt-0 pb-3">{{ cardTitle }}
      <div class="flex-grow-1"></div>
      <v-btn title="Data download" text small :loading="loading" icon color="rgba(171, 100, 194, 0.6)" @click="dl_csv">
        <v-icon class="icon-lg">$riwIconSet_csv</v-icon>
      </v-btn>
    </v-card-title>
    <v-overlay
      absolute
      opacity=0
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="rgba(171, 100, 194, 0.6)"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay
      absolute
      :value="loading"
    >
    </v-overlay>
    <v-overlay
      z-index="2"
      absolute
      :value="noData"
      opacity="0.2"
    >
      <p style="color: rgba(255, 255, 255, .3); font-size: min(100px, max(6vw, 56px)); font-weight: bold;">
        No Data
      </p>
    </v-overlay>
    <div class="smy_legend_padding">
      <v-card-text>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col cols=12 class="pa-0">
              <vue-plotly v-if="metadata && !loading" class="plotlyDiv" :data="histData.data" :layout="histData.layout" :options="generic_chart_options" :autoResize="autoResize" ></vue-plotly>
            </v-col>
          </v-row>
          <v-row v-if="outliers > 0 && !loading" class="pa-0 ma-0 mt-2">
            <v-col class="pa-0 ma-0">
              <v-switch hide-details inset v-model="showOutliers" label="Show outliers" class="mt-0"></v-switch>
            </v-col>
            <v-col class="pa-0 ma-0 mt-1">
              <span style="#1A1A1A;" ><span v-if="showOutliers">(Showing {{outliers}} outliers)</span><span v-else>(Hiding {{outliers}} outliers)</span></span>
            </v-col>
            <v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </div>
  </v-card>
</template>

<style scoped>
.plotlyDiv {
  width: 100%;
  height: 300px !important;
  margin-left: auto;
  margin-right: auto;
}
loading {
  opacity: 0.6
}
* >>> .hovertext {
  opacity: 0.8
}
.smy_subheading {
  padding: 6px 0px 6px 8px;
  margin-top: -32px !important;
  color: grey;
}
.histogram-card {
  min-height: 431px;
}
</style>

<script>

import witnessHistogramComponentModel from '@/component-models/witness-histogram-component-model';

export default witnessHistogramComponentModel;

</script>
