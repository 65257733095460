<template>
<div ref="container" class="vue-plotly"/>
</template>
<script>
// import Plotly from 'plotly.js-dist'
// Use a special minimal bundle (subset of the full plotly.js)
// See here for details: https://github.com/plotly/plotly.js/blob/master/dist/README.md)
// import Plotly from 'plotly.js-cartesian-dist' 
// import Plotly from 'plotly.js-basic-dist'
// import Plotly from 'plotly.js-basic-dist-min'
// import Plotly from 'plotly.js-dist'
// import Plotly from 'plotly.js-finance-dist-min'
import Plotly from 'plotly.js-strict-dist-min';
// import QOL from '@/components/qol'
// import LogRocket from 'logrocket'
// import debounce from 'lodash/debounce'
// import defaults from 'lodash/defaults'

// var orgLocale = Plotly.d3.locale
// Plotly.d3.locale = (locale) => {
//   var result = orgLocale(locale)
//   var orgNumberFormat = result.numberFormat
//   result.numberFormat = (format) => {
//     if (format !== 'smytime') {
//       return orgNumberFormat(format)
//     }
//     return (x) => {
//       return Math.pow(2, Number.parseFloat(x)).toString()
//     }
//   }
//   return result
// }

const events = [
  'click',
  'hover',
  'unhover',
  'selecting',
  'selected',
  'restyle',
  'relayout',
  'autosize',
  'deselect',
  'doubleclick',
  'redraw',
  'animated',
  'afterplot'
];
const functions = [
  'restyle',
  'relayout',
  'update',
  'addTraces',
  'deleteTraces',
  'moveTraces',
  'extendTraces',
  'prependTraces',
  'purge'
];
const methods = functions.reduce((all, funcName) => {
  all[funcName] = function (...args) {
    return Plotly[funcName].apply(Plotly, [this.$refs.container].concat(args));
  };
  return all;
}, {});
methods.hover = function (...args) {
  return Plotly.Fx.hover.apply(Plotly, [this.$refs.container].concat(args));
};
export default {
  props: {
    autoResize: Boolean,
    // watchShallow: Boolean,
    options: {
      type: Object
    },
    data: {
      type: Array
    },
    layout: {
      type: Object
    }
  },
  data () {
    return {
      internalLayout: {
        ...this.layout,
        datarevision: 1
      },
      watchShallow: false
    };
  },
  mounted () {
    this.react();
    // this.newPlot()
    this.init();
    this.initEvents();
    this.$watch('data', () => {
      this.internalLayout.datarevision++;
      this.react();
    }, { deep: !this.watchShallow });
    this.$watch('options', this.react, { deep: !this.watchShallow });
    this.$watch('layout', this.relayout, { deep: !this.watchShallow });
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.__resizeListener);
    this.__generalListeners.forEach(obj => this.$refs.container.removeAllListeners(obj.fullName));
    Plotly.purge(this.$refs.container);
  },
  methods: {
    init: function () {
      this.watchShallow = false;
    },
    initEvents () {
      if (this.autoResize) {
        this.__resizeListener = () => {
          this.internalLayout.datarevision++;
          // debounce(this.react, 200)
          this.react();
        };
        window.addEventListener('resize', this.__resizeListener);
      }
      this.__generalListeners = events.map((eventName) => {
        return {
          fullName: 'plotly_' + eventName,
          handler: (...args) => {
            this.$emit.apply(this, [eventName].concat(args));
          }
        };
      });
      this.__generalListeners.forEach((obj) => {
        this.$refs.container.on(obj.fullName, obj.handler);
      });
      // console.log(this.$refs)
    },
    ...methods,
    toImage (options) {
      const el = this.$refs.container;
      let opts = {
        format: 'png',
        width: el.clientWidth,
        height: el.clientHeight
      };

      if (options !== undefined) {
        opts = options;
      }

      return Plotly.toImage(this.$refs.container, opts);
    },
    downloadImage (options) {
      const el = this.$refs.container;
      let opts = {
        format: 'png',
        width: el.clientWidth,
        height: el.clientHeight,
        filename: (el.layout.title || 'plot') + ' - ' + new Date().toISOString()
      };

      if (options !== undefined) {
        opts = options;
      }

      return Plotly.downloadImage(this.$refs.container, opts);
    },
    plot: async function () {
      return Plotly.plot(this.$refs.container, this.data, this.internalLayout, this.getOptions()).catch(err => {
        // LogRocket.captureException(err)
      });
    },
    getOptions () {
      const el = this.$refs.container;
      let opts = this.options;
      // if width/height is not specified for toImageButton, default to el.clientWidth/clientHeight
      if (!opts) opts = {};
      if (!opts.toImageButtonOptions) opts.toImageButtonOptions = {};
      if (!opts.toImageButtonOptions.width) opts.toImageButtonOptions.width = el.clientWidth;
      if (!opts.toImageButtonOptions.height) opts.toImageButtonOptions.height = el.clientHeight;
      return opts;
    },
    newPlot: async function () {
      return Plotly.newPlot(this.$refs.container, this.data, this.internalLayout, this.getOptions()).catch(err => {
        // LogRocket.captureException(err)
      });
    },
    react: async function () {
      return Plotly.react(this.$refs.container, this.data, this.internalLayout, this.getOptions()).catch(err => {
        // LogRocket.captureException(err)
      });
    }
  }
};
</script>
